<template>
  <div>
    <location />
    <div class="page wrap-1200 course-info">
      <p class="course-name border-top-dgray border-bottom-gray fs-lg-18 lh-sm">
        {{ basicinfo.coursename }}
      </p>

      <div class="row g-0 border-bottom-gray">
        <div class="col-md-6 d-flex position-relative">
          <img :src="(basicinfo.path + '/' + basicinfo.image) | get_img" />
          <div class="course-state">
            <div class="prog-tournament bg-black" v-if="tournamentlist.length">
              T
            </div>
            <div class="prog-event bg-pink" v-if="awardrulelist.length">E</div>
          </div>
        </div>

        <div class="col-md-6 d-flex flex-column border-top border-md-0">
          <div class="row g-0 border-bottom h-100">
            <div class="col bg-gray d-flex justify-content-center">
              <label class="d-table-cell align-self-center">
                {{ $t("title.golfcourse.golfcourse_difficulty") }}
              </label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                <i
                  class="material-icons-outlined"
                  :class="
                    basicinfo.field >= index ? 'text-yellow' : 'text-lightgray'
                  "
                  v-for="index in 5"
                  :key="index"
                >
                  star
                </i>
              </p>
            </div>
          </div>
          <div class="row g-0 border-bottom h-100">
            <div class="col bg-gray d-flex justify-content-center">
              <label class="d-table-cell align-self-center">
                {{ $t("title.golfcourse.green_difficulty") }}
              </label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                <i
                  class="material-icons"
                  :class="
                    basicinfo.green >= index ? 'text-green' : 'text-lightgray'
                  "
                  v-for="index in 5"
                  :key="index"
                >
                  flag
                </i>
              </p>
            </div>
          </div>
          <div class="row g-0 border-bottom h-100">
            <div class="col bg-gray d-flex justify-content-center">
              <label class="d-table-cell align-self-center"
                >{{ $t("title.common.hole") }} /
                {{ $t("title.common.par") }}</label
              >
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{ basicinfo.holesum + "H" + " / " + "PAR" + basicinfo.parsum }}
              </p>
            </div>
          </div>
          <div class="row g-0 h-100">
            <div class="col bg-gray d-flex justify-content-center">
              <label class="d-table-cell align-self-center">{{
                $t("title.common.distance")
              }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{ basicinfo.distance | comma }}m
              </p>
            </div>
          </div>
        </div>
      </div>

      <p class="mt-3 text-subscript fs-14 text-orange">
        <span class="material-icons-outlined me-1">info</span
        >{{ $t("text.golfcourse.title_information") }}
      </p>

      <!-- Sub Course -->
      <div
        v-for="(anc, index) in subcourse"
        :key="index"
        class="course-details"
      >
        <p class="info">
          {{ $t("title.common.subcourse") }} {{ anc.subcourseseq }} [{{
            anc.title
          }}]
        </p>
        <div class="position-relative">
          <table class="table scorecard_title">
            <thead>
              <tr class="bg-gray">
                <th>{{ $t("title.common.hole") }}</th>
              </tr>
              <tr class="bg-gray">
                <th>{{ $t("title.common.par") }}</th>
              </tr>
              <tr class="bg-gray">
                <th class="border-bottom-0">
                  {{ $t("title.common.handicap") }}
                </th>
              </tr>
            </thead>
          </table>
          <div class="courselist view list" v-if="anc.subcoursehole.length > 0">
            <div class="in overflow-auto fix-table">
              <table class="table">
                <tbody>
                  <tr>
                    <td
                      v-for="(inc, index) in anc.subcoursehole"
                      :key="index"
                      @click="showModal(inc.hole_no, anc.subcourseseq)"
                      class="position-relative"
                    >
                      <span class="rounded-circle info-hole"
                        >{{ inc.hole_no }}
                      </span>
                      <span
                        class="position-absolute top-0 start-0 badge bg-pink"
                        v-if="
                          awardrulelist.find((v) => v.hole_fk == inc.hole_pk)
                        "
                      >
                        E
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      v-for="(inc, index) in anc.subcoursehole"
                      :key="index"
                      @click="showModal(inc.hole_no, anc.subcourseseq)"
                    >
                      <span class="rounded-circle info-par">{{
                        inc.par_score
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      v-for="(inc, index) in anc.subcoursehole"
                      :key="index"
                    >
                      {{ inc.handicap }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <h6 class="mt-5 mb-3 fs-6 ls-0" v-if="tournamentlist.length">
        {{ $t("text.golfcourse.tournament_in_prog") }}
      </h6>
      <table class="table board table-sm" v-if="tournamentlist.length">
        <thead>
          <tr>
            <th class="d-table-cell">
              {{ $t("title.common.title") }}
            </th>
            <th class="d-none d-sm-table-cell">
              {{ $t("title.common.date") }}
            </th>
            <th class="d-none d-lg-table-cell">
              {{ $t("title.common.entry_limit") }}
            </th>
            <th class="d-none d-md-table-cell">
              {{ $t("title.common.status") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in tournamentlist">
            <tr :key="index">
              <td class="d-table-cell text-start">
                <router-link
                  :to="{
                    name: 'Condition',
                    params: { id: item.tournamentinfo.tournamentno },
                    query: {
                      searchtype: searchData.searchtype,
                      searchname: searchData.searchname,
                    },
                  }"
                  class="d-inline-block pointer"
                >
                  <span class="fs-12 text-capitalize text-black">{{
                    tournament_type(item.tournamentinfo.type)
                  }}</span
                  ><br />
                  <span class="more">{{ item.tournamentinfo.title }}</span>
                </router-link>
              </td>
              <td class="d-none d-sm-table-cell">
                <!-- 서비스 시간 -->
                <p class="text-date">
                  <dfn
                    class="time-zone-mark badge rounded-pill bg-green"
                    title="Standard time"
                    >ST</dfn
                  >

                  {{
                    item.tournamentinfo.timezone_startdate.timezone_common
                      | dateformat
                  }}
                  ~
                  {{
                    item.tournamentinfo.timezone_enddate.timezone_common
                      | dateformat
                  }}
                </p>
              </td>
              <td class="d-none d-lg-table-cell text-red">
                {{ item.tournamentinfo.entrylimit | transToValue("∞") }}
              </td>
              <td class="d-none d-md-table-cell">
                {{ tournament_status(item.tournamentinfo.status) }}
              </td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="d-lg-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="4">
                <div class="toggle-content">
                  <div class="row d-sm-none">
                    <div class="col-4">{{ $t("title.common.date") }}</div>
                    <div class="col-8">
                      <!-- 서비스 시간 -->
                      <p class="text-date">
                        <dfn
                          class="time-zone-mark badge rounded-pill bg-green"
                          title="Standard time"
                          >ST</dfn
                        >

                        {{
                          item.tournamentinfo.timezone_startdate.timezone_common
                            | dateformat
                        }}
                        ~
                        {{
                          item.tournamentinfo.timezone_enddate.timezone_common
                            | dateformat
                        }}
                      </p>
                    </div>
                  </div>

                  <div class="row d-lg-none">
                    <div class="col-4">
                      {{ $t("title.common.entry_limit") }}
                    </div>
                    <div class="col-8 text-red">
                      {{ item.tournamentinfo.entrylimit | transToValue("∞") }}
                    </div>
                  </div>

                  <div class="row d-md-none">
                    <div class="col-4">{{ $t("title.common.status") }}</div>
                    <div class="col-8">
                      {{ tournament_status(item.tournamentinfo.status) }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>

      <div class="btn-bottom-wrapper">
        <b-button
          size="xl"
          variant="outline-primary"
          :aria-label="$t('button.common.list')"
          @click="
            $router.push({
              name: 'CourseList',
              query: {
                countryno: $route.query.countryno,
              },
            })
          "
        >
          {{ $t("button.common.list") }}
        </b-button>
      </div>
    </div>

    <b-modal
      id="modal-hole-info"
      ref="modal-hole-info"
      size="xl"
      centered
      scrollable
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header>
        <h3>
          <p class="lh-sm">
            {{ $t("title.common.subcourse") }}
            {{ hole_model.coursubtype }} [{{ hole_model.description }}]
          </p>
          <!-- ({{ hole_model.subcoursename }}) -->
          <div class="hole-details mt-2">
            <span>{{ $t("title.common.hole") }} {{ hole_model.hole }}</span>
            <span>{{ $t("title.common.par") }} {{ hole_model.par }}</span>
            <span
              >{{ $t("title.common.handicap") }} {{ hole_model.handicap }}</span
            >
          </div>
        </h3>
        <b-button
          variant="close"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-hole-info')"
        >
        </b-button>
      </template>

      <template v-if="items.length">
        <p class="modal-subtitle">{{ $t("text.golfcourse.event_in_prog") }}</p>
        <table class="table board table-sm toggle-xl mb-4 border-1">
          <thead>
            <tr>
              <th class="d-lg-table-cell">
                {{ $t("title.common.title") }}
              </th>
              <th class="d-none d-lg-table-cell">
                {{ $t("title.common.date") }}
              </th>
              <th class="d-none d-xl-table-cell">
                {{ $t("title.common.entry_limit") }}
              </th>
              <th class="d-none d-xl-table-cell">
                {{ $t("title.common.status") }}
              </th>
              <th class="d-none d-lg-table-cell w-1">
                {{ $t("title.common.leaderboard") }}
              </th>
              <th class="th-arrow">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in items">
              <tr :key="index">
                <td class="d-lg-table-cell text-start">
                  <a
                    class="d-inline-block pointer"
                    @click="showModal2(item.eventno)"
                  >
                    <span class="fs-12 text-capitalize text-pink">{{
                      event_type(item.type)
                    }}</span
                    ><br />
                    <span class="more">{{ item.title }}</span>
                  </a>
                </td>

                <td class="d-none d-lg-table-cell">
                  <p class="text-date">
                    <dfn
                      class="time-zone-mark badge rounded-pill bg-green"
                      title="Standard time"
                      >ST</dfn
                    >
                    {{ item.timezone_startdate.timezone_common | dateformat }}
                    ~
                    {{ item.timezone_enddate.timezone_common | dateformat }}
                  </p>
                </td>

                <td class="d-none d-xl-table-cell text-red">
                  {{ item.entrylimit | transToValue("∞") }}
                </td>
                <td class="d-none d-xl-table-cell">
                  {{ event_status(item.status_cd) }}
                </td>
                <td class="d-none d-lg-table-cell w-1">
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-xs"
                    v-if="item.type > 10"
                    @click="
                      $router.push({
                        name: 'MiniGameRanking',
                        params: { eventno: item.eventno },
                      })
                    "
                  >
                    {{ $t("button.common.view") }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-xs"
                    v-else
                    @click="
                      $router.push({
                        name: 'WinAMiniGameList',
                        params: { eventno: item.eventno },
                      })
                    "
                  >
                    {{ $t("button.common.view") }}
                  </button>
                </td>
                <td class="td-arrow" @click="toggle_tr(index)">
                  <img
                    src="/img/arrow_none_collapsed.png"
                    :class="{ 'btn-toggle-arrow': view_hide === index }"
                  />
                </td>
              </tr>
              <tr
                class="d-xl-none"
                :class="{ show: view_hide === index }"
                :key="index + 'r'"
                v-if="view_hide === index"
              >
                <td colspan="5">
                  <div class="toggle-content">
                    <div class="row d-lg-none">
                      <div class="col-4">{{ $t("title.common.date") }}</div>
                      <div class="col-8">
                        <!-- 서비스 시간 -->
                        <p class="text-date">
                          <dfn
                            class="time-zone-mark badge rounded-pill bg-green"
                            title="Standard time"
                            >ST</dfn
                          >
                          {{
                            item.timezone_startdate.timezone_common | dateformat
                          }}
                          ~
                          {{
                            item.timezone_enddate.timezone_common | dateformat
                          }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-4">
                        {{ $t("title.common.entry_limit") }}
                      </div>
                      <div class="col-8 text-red">
                        {{ item.entrylimit | transToValue("∞") }}
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-4">{{ $t("title.common.status") }}</div>
                      <div class="col-8">
                        {{ event_status(item.status_cd) }}
                      </div>
                    </div>

                    <div class="row d-lg-none">
                      <div class="col-4">
                        {{ $t("title.common.leaderboard") }}
                      </div>
                      <div class="col-8">
                        <button
                          type="button"
                          class="btn btn-outline-primary btn-xs"
                          v-if="item.type > 10"
                          @click="
                            $router.push({
                              name: 'MiniGameRanking',
                              params: { eventno: item.eventno },
                            })
                          "
                        >
                          {{ $t("button.common.view") }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-outline-primary btn-xs"
                          v-else
                          @click="
                            $router.push({
                              name: 'WinAMiniGameList',
                              params: { eventno: item.eventno },
                            })
                          "
                        >
                          {{ $t("button.common.view") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>

      <div class="minimap row g-4">
        <div class="col">
          <div class="card h-100">
            <h6 class="p-3 fs-6 fs-lg-18 border-bottom">
              {{ $t("title.golfcourse.golfcourse_minimap") }}
            </h6>
            <div class="card-body">
              <img
                :src="
                  (hole_model.fieldpath + '/' + hole_model.fieldmap) | get_img
                "
              />
            </div>
          </div>
        </div>

        <template v-if="hole_model.greenmapl != null">
          <div class="col">
            <div class="card h-100">
              <h6 class="p-3 fs-6 fs-lg-18 border-bottom">
                {{ $t("title.golfcourse.green_minimap_left") }}
              </h6>
              <div class="card-body">
                <img
                  :src="
                    (hole_model.greenpathl + '/' + hole_model.greenmapl)
                      | get_img
                  "
                  v-if="hole_model.greenmapl != null"
                />
              </div>
            </div>
          </div>
        </template>

        <template v-if="hole_model.greenmapr != null">
          <div class="col">
            <div class="card h-100">
              <h6 class="p-3 fs-6 fs-lg-18 border-bottom">
                {{ $t("title.golfcourse.green_minimap_right") }}
              </h6>
              <div class="card-body">
                <img
                  :src="
                    (hole_model.greenpathr + '/' + hole_model.greenmapr)
                      | get_img
                  "
                  v-if="hole_model.greenmapr != null"
                />
              </div>
            </div>
          </div>
        </template>
      </div>

      <template #modal-footer="{}">
        <b-button
          size="md"
          variant="outline-secondary"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-hole-info')"
        >
          {{ $t("button.common.close") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-minigame-info"
      ref="modal-minigame-info"
      size="lg"
      centered
      scrollable
      hide-footer
    >
      <template #modal-header>
        <h3>{{ $t("menu.event.minigame_informaition") }}</h3>
        <b-button
          variant="close"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-minigame-info')"
        >
        </b-button>
      </template>

      <span class="mb-2 badge bg-pink text-capitalize">
        {{ event_type(eventinfo.type) }}
      </span>
      <p class="modal-subtitle">
        {{ eventinfo.title }}
      </p>

      <div class="row row-cols-1 row-cols-lg-auto gx-2 gy-1 lh-sm">
        <div class="col text-date">
          <!-- 서비스 시간 -->
          <dfn
            class="time-zone-mark badge rounded-pill bg-green"
            title="Standard time"
            >ST</dfn
          >
          {{
            eventinfo.timezone_startdate &&
            eventinfo.timezone_startdate.timezone_common | dateformat
          }}
          ~
          {{
            eventinfo.timezone_enddate &&
            eventinfo.timezone_enddate.timezone_common | dateformat
          }}
        </div>
      </div>

      <div class="row g-0 table-type header-col p-keep mt-3">
        <div class="col-sm-4">
          {{ $t("title.common.course") }}
        </div>
        <div class="col-sm-8">
          {{ eventinfo.coursename }} [{{ eventinfo.description }}]
        </div>

        <div class="col-sm-4">
          {{ $t("title.common.hole") }}
        </div>
        <div class="col-sm-8">{{ eventinfo.hole }}H</div>

        <div class="col-sm-4">
          {{ $t("title.common.level") }}
        </div>
        <div class="col-sm-8">
          {{ event_level(eventinfo.level_fk) }}
        </div>

        <div class="col-sm-4">
          {{ $t("title.common.entry_limit") }}
        </div>
        <div class="col-sm-8 text-red">
          {{ eventinfo.entrylimit | transToValue("∞") }}
        </div>

        <div class="col-sm-4">
          {{ $t("title.common.teebox") }}
        </div>
        <div class="col-sm-8">
          <div class="row row-cols-1 row-cols-lg-auto gy-2">
            <div class="col">
              {{ $t("text.common.male") }} :
              {{ eventinfo.teeboxman | teeboxType }}
            </div>
            <div class="col">
              {{ $t("text.common.female") }} :
              {{ eventinfo.teeboxwoman | teeboxType }}
            </div>
          </div>
        </div>

        <template v-if="eventinfo.type == '9' || eventinfo.type == '10'">
          <div class="col-sm-4">
            {{ $t("title.common.distance") }}
          </div>
          <div class="col-sm-8">
            {{
              eventinfo.distance != "0"
                ? eventinfo.distance + "m"
                : $t("text.common.unregistered")
            }}
          </div>
        </template>

        <div class="col-sm-4">
          {{ $t("title.common.prizes") }}
        </div>
        <div class="col-sm-8 text-prize" v-if="eventinfo.type < 11">
          {{ eventinfo.point }}
        </div>
        <div class="col-sm-8 text-point" v-else>
          <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-2 g-xl-3">
            <template v-for="(item, index) in awardprizeranklist">
              <div :key="'a' + index" class="col">
                <div class="card h-100">
                  <div class="card-body w-100">
                    <h6 class="card-title fs-6">
                      {{ $t("title.common.top_rank") }} {{ item.rank_no }}
                    </h6>
                    <p class="card-text pt-1 lh-sm text-prize">
                      {{ item.prize }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <template v-if="eventinfo.standard_point != 0">
          <div class="col-sm-4">
            {{ $t("title.common.point_allocation") }}
          </div>
          <div class="col-sm-8 text-point-sp">
            {{ $t("title.common.standard_point") }}
          </div>
        </template>
      </div>

      <!-- <template #modal-footer="{}">
        <b-button
          size="md"
          variant="outline-secondary"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-minigame-info')"
        >
          {{ $t("button.common.close") }}
        </b-button>
      </template> -->
    </b-modal>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/golfcourse";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "CourseView",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        courseno: this.$route.params.id || "",
      },
      basicinfo: [
        {
          area: "",
          coursename: "",
          path: "",
          image: "",
          field: "",
          green: "",
          hole: "",
          par: "",
          distance: "",
        },
      ],
      // tourdefine: { list: [], tournamentlist: [] },
      tournamentlist: [],
      awardrulelist: [],
      subcourse: [
        {
          subcourseseq: "",
          subcourseno: "",
          subcoursename: "",
          subcoursehole: [],
        },
      ],
      hole_model: {
        hole: "",
        coursubtype: "",
        subcoursename: "",
        par: "",
        fieldpath: "",
        fieldmap: "",
        greenpathl: "",
        greenmapl: "",
        greenpathr: "",
        greenmapr: "",
        description: "",
      },
      subcourselist: [],
      subcourselistcnt: 0,
      scroll: "r",
      hold: false,
      inter_clear: Object,
      items: [],
      view_hide: null,
      view_hide2: null,
      eventinfo: {},
      awardprizeranklist: [],
    };
  },
  methods: {
    showModal2(eventno) {
      // console.log(hole,type);
      ax.get_eventview(eventno, (flag, data) => {
        // console.log(flag, data);
        if (flag) {
          this.eventinfo = data.eventinfo;
          this.eventinfo.startdate = this.$moment(
            this.eventinfo.startdate
          ).format("YYYY-MM-DD");
          this.eventinfo.enddate = this.$moment(this.eventinfo.enddate).format(
            "YYYY-MM-DD"
          );
          this.awardprizeranklist = data.awardprizerank;
          this.$refs["modal-minigame-info"].show();
        } else {
          alert(data.message);
        }
      });
    },
    //hideModal2() {
    //  this.$refs["modal-minigame-info"].hide();
    //},
    get_courseview() {
      ax.get_courseview(this.searchData, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          this.basicinfo = data.basicinfo;
          this.tournamentlist = data.tournamentlist;
          this.awardrulelist = data.awardrulelist;

          this.subcourse = data.subcourse || [];
        } else {
          alert(data);
        }
      });
    },
    showModal(hole, type) {
      // console.log(hole,type);
      ax.get_courseholeview(
        this.searchData.courseno,
        hole,
        type,
        (flag, data) => {
          if (flag) {
            console.log(flag, data);
            this.hole_model.hole = hole;
            this.hole_model.coursubtype = type;
            this.hole_model.subcoursename = data.courseholeview.subcoursename;
            this.hole_model.par = data.courseholeview.par;
            this.hole_model.fieldpath = data.courseholeview.fieldpath;
            this.hole_model.fieldmap = data.courseholeview.fieldmap;
            this.hole_model.greenpathl = data.courseholeview.greenpathl;
            this.hole_model.greenmapl = data.courseholeview.greenmapl;
            this.hole_model.greenpathr = data.courseholeview.greenpathr;
            this.hole_model.greenmapr = data.courseholeview.greenmapr;
            this.hole_model.description = data.courseholeview.description;
            this.hole_model.handicap = data.courseholeview.handicap;
            this.items = data.eventlist;
            //this.$refs["modal-hole-info"].modalOuterStyle.zIndex = 9999;
            this.$refs["modal-hole-info"].show();
          } else {
            alert(data);
          }
        }
      );
    },
    hideModal() {
      this.$refs["modal-hole-info"].hide();
    },
    cc_img() {
      if (this.basicinfo.image !== null)
        return this.basicinfo.path + "/" + this.basicinfo.image;
      else return "/img/noccimage.jpg";
    },
    scroll_x() {
      let content = document.querySelector(".list");
      let scrollAmount = 0;
      if (this.scroll === "r") {
        //content.scrollLeft += 40
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft += 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      } else {
        //content.scrollLeft -= 40
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft -= 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      }
    },
    course_click(direction) {
      let scrollAmount = 0;
      let content = document.querySelector(".list");
      if (direction === "r") {
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft += 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      } else {
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft -= 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      }
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    toggle_tr2(index) {
      if (this.view_hide2 === index) this.view_hide2 = null;
      else this.view_hide2 = index;
    },
  },
  created() {
    this.get_courseview();
  },
  watch: {
    hold: function () {
      if (this.hold) {
        this.inter_clear = setInterval(this.scroll_x, 1000);
      } else {
        clearInterval(this.inter_clear);
      }
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_eventwinninglist();
    },
    "searchData.searchtype": function () {
      this.searchData.pageno = 1;
      this.get_eventwinninglist();
    },
    "searchData.type": function () {
      this.searchData.pageno = 1;
      this.get_eventwinninglist();
    },

    "searchData.pageno": function () {
      this.get_eventwinninglist();
    },
  },

  mixins: [myMixin],
};
</script>
